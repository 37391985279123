import React from 'react';
//import './i18n.js';

//import { withTranslation } from 'react-i18next';
//import { Trans } from 'react-i18next';

class Template extends React.Component {
  render() {
    return (
      <div className="fullpage">
        <div className="fejlectarto">
          <div className="fejlec">
            <div className="logo">
              <img src="https://www.studicore.hu/img/StudiCore_logo-300x41.png" alt="StudiCore" width="300" height="41"/>
            </div>
            {this.props.titlecomp}
          </div>
        </div>
        <div className="hatter">
            {this.props.maincomp}
        </div>
        </div>
    );
  }
}

//const I18NTemplate = withTranslation()(Template)

export default Template;
