import React from "react";

import { useTranslation } from 'react-i18next';

import SAPForm from './SAPForm.js';
import Share from './Share.js';

function calculate_points(line) {
  if (line !== undefined && line.error === undefined) {
    var points = 0;
    for (var i = 0; i < line.length; i++) {
      points += line[i].points * line[i].maxpoints / 100;
    }
    return points;
  }
  return NaN;
}

// points --> remarkable, excellent, superb, very good, great job ID
function evaluation(points) {
  if (points >= 85) {
    return 'evaluation-remarkable';
  } else if (points >= 70) {
    return 'evaluation-excellent';
  } else if (points >= 55) {
    return 'evaluation-superb';
  } else if (points >= 40) {
    return 'evaluation-very-good';
  } else {
    return 'evaluation-great-job';
  }

}

function Points(props) {
  const { t } = useTranslation();

  var points = 0;
  if (props.line !== undefined) {
    if (props.line.error !== undefined) {
      return (
        <div className="erroreval eval">
          {t('Belső hiba! Kérlek értesítsd az ügyfélszolgálatot', {support_email: process.env.REACT_APP_SUPPORT_EMAIL})}
        </div>
      );
    }
    points = calculate_points(props.line);
/*    for (var i = 0; i < props.line.length; i++) {
      points += props.line[i].points * props.line[i].maxpoints / 100;
    }*/
    if (isNaN(points)) {
      return (
        <div className="eval">
          <span dangerouslySetInnerHTML={{__html: t('Kiértékelés folyamatban')}}/>
        </div>
      );
    }
    if (process.env.REACT_APP_MIN_REQUIRED_POINTS !== "0") {
      if (points >= 90) {
        return (
          <div>
            <div className="excellenteval eval">
              <span dangerouslySetInnerHTML={{__html: t('points_over_90', {points: Number(points).toFixed(2)}) }}></span>
              <Share points={points} />
            </div>
            <div className="infoeval eval">
              <SAPForm points={points} email={props.email} mmdata={props.mmdata}/>
            </div>
          </div>
        );
      } else if (points >= process.env.REACT_APP_MIN_REQUIRED_POINTS) {
        return (
          <div>
            <div className="mediumeval eval">
              <span dangerouslySetInnerHTML={{__html: t('points_good_enough', {points: Number(points).toFixed(2)})}}/>
              <Share points={points} />
            </div>
            <div className="infoeval eval">
              <SAPForm points={points} email={props.email} mmdata={props.mmdata}/>
            </div>
          </div>
        );
      } else if (points >= 0){
        //console.log(this.props.onEvaluate);
        return (
          <div className="badeval eval">
            <span dangerouslySetInnerHTML={{__html: t('points_not_enough', {points: Number(points).toFixed(2)})}}/>
            <Share points={points} />
            <form onSubmit={props.onEvaluate}>
              <input type="submit" value={t('Megpróbálom újra!')} className="gomb"/>
            </form>
          </div>
        );
      }
    } else { // REACT_APP_MIN_REQUIRED_POINTS == 0
      const evalText = t(evaluation(points));
      return (
        <div>
          <div className="goodeval eval">
            <span dangerouslySetInnerHTML={{__html: t('points_no_minimum', {points: Number(points).toFixed(2)})}}/><br/>
            <span>{t('evaluation-congratulations')} {evalText}</span>
            <Share points={points} />
          </div>
          <div className="infoeval eval">
            <SAPForm points={points} email={props.email} mmdata={props.mmdata}/>
          </div>
        </div>
      )
    }
  }
  return <div>{points}</div>
}

export default Points;
export { calculate_points }
