import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

import Template from './template/Template';
import ProgQuiz from './ProgQuiz';

ReactDOM.render(
    <Suspense fallback={<Template titleComp="Loading..." mainComp="Loading..."/>}>
      <ProgQuiz />
    </Suspense>,
  document.getElementById('root'));
registerServiceWorker();

/* Mintaként: RadioQuiz
return (<RadioQuiz
          text="Kérdés szövege"
          num={this.state.num}
          options={[
            {value: 'a', label: 'a változó egyidőben több adatot is tárolhat'},
            {value: 'b', label: 'a számítógépek adatokkal dolgoznak'},
            {value: 'c', label: 'az "int a;" utasítás hatására létrejön egy "a" nevű változó'},
            {value: 'd', label: 'az "=" hatására a jobb oldalon álló értékkel felülírjuk a bal oldalon álló változó tartalmát'},
            {value: 'e', label: 'a változó értéke a program végéig az marad, amit először kapott'},
            {value: 'f', label: 'a számítógép ismeri az eltárolt adat értelmét'},
            {value: 'g', label: 'a változónak van neve'}
          ]}
          onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
          onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
        />);*/
