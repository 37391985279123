import React, { Component } from 'react';
import {
  FacebookShareButton,
  EmailShareButton,
  FacebookIcon,
  EmailIcon,
} from 'react-share';

import '../i18n.js';
import '../Share.css';
import { withTranslation } from 'react-i18next';

class Sh extends Component {
  render() {
    const { t } = this.props;
    const shareUrl = process.env.REACT_APP_SHARE_URL;
    const title = t('points-achieved', {count: this.props.points});

    return (
      <div className="Demo__container">
        <div><b>{t('Oszd meg az eredményed az ismerőseiddel')}</b></div>
        <div className="Demo__some-network">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

        </div>

        <div className="Demo__some-network">
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body={t('toltsd-ki-te-is')}
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </div>);
  }
}

const Share = withTranslation()(Sh);
export default Share;
