import React from 'react';
import I18NQuestionTemplate from '../template/I18NQuestionTemplate';
import { Trans } from 'react-i18next';

class CheckBoxQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    var array = {};
    for (var i = 0; i < props.options.length; i++) {
      array[props.options[i].value] = false;
    }
    this.state = array;
  }
  handleChange(event) {
    //alert(event.target.value + ' ' + event.target.checked);
    let key = event.target.value;
    let val = event.target.checked;
    let obj = {};
    obj[key] = val;
    let stateCopy = Object.assign({}, this.state);
    stateCopy[key] = val;
    //alert(stateCopy.toSource());
    this.setState(stateCopy);
    this.props.onChangeValue(stateCopy);
  }
  render() {
    //var percent = (this.props.num-0.95) / this.props.all * 100;
    var elements = [];
    for (var i = 0; i < this.props.options.length; i++) {
      elements.push(
        <div key={i}>
          <input type="checkbox" className="checkboxquiz-input"
            name={"cbq"+i} value={this.props.options[i].value}
            onChange={this.handleChange}
            id={"cbq"+i}/>
          <label htmlFor={"cbq"+i}>
            {this.props.options[i].label}
          </label>
        </div>
      );
    }
    return (
      <I18NQuestionTemplate
        formContent=<div><p><Trans>Válassz ki egyet vagy többet</Trans></p>
                      <div className="checkboxes">
                        {elements}
                      </div></div>
        {...this.props}
      />
    );
  }
}
export default CheckBoxQuiz;
