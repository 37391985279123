import React from 'react'
import '../i18n.js';
import { withTranslation } from 'react-i18next';

class C extends React.Component {
  render() {
    const { t } = this.props;
    var parts = t(this.props.textKey).split("%");
    if (parts.length > this.props.components.length + 1) {
      throw new Error("too few subcomponents");
    }
    var elements = [];
    for (var i = 0; i < parts.length-1; i++) {
      elements.push(<span key={i*2} dangerouslySetInnerHTML={{__html: parts[i]}}/>);
      elements.push(<span key={i*2+1}>{this.props.components[i]}</span>);
    }
    elements.push(<span key={parts.length*2-1} dangerouslySetInnerHTML={{__html: parts[parts.length-1]}}/>);
    return(
      <div>{elements}</div>
    );
  }
}

const I18NComponent = withTranslation()(C);
export default I18NComponent;
