import React from 'react';
import './index.css';

import './i18n.js';
import Template from './template/Template.js';

import { withTranslation } from 'react-i18next';


class InfoPage extends React.Component {
  render() {
    const { t } = this.props;
    var text = this.props.text;
    if (text === undefined) {
      text = t(this.props.textKey, this.props.params);
    }
    var title = this.props.title;
    if (title === undefined) {
      title = t(this.props.titleKey, this.props.params);
    }
    return (
      <Template
        titlecomp=<h1>{title}</h1>
        maincomp=<div className="questionbox"><div dangerouslySetInnerHTML={{__html: text}}></div></div>
      />
    );
  }
}

const I18NInfoPage = withTranslation()(InfoPage)

export default I18NInfoPage;
