import React from 'react';
//import './index.css';

import { withTranslation } from 'react-i18next';

class Var extends React.Component {
    render() {
      const { t } = this.props;
      var tartalom = t('valtozok');
        return (
          <div className="valtozok" dangerouslySetInnerHTML={{__html: tartalom}}>
          </div>
        );
    }
}

const Variables = withTranslation()(Var);
export default Variables;
