import React from 'react';
//import './index.css';
import Variables from './Valtozok.js'

import { withTranslation } from 'react-i18next';

class SH extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showResults: false };
        this.onClick = this.onClick.bind(this);
    }
    onClick() {
      if (this.state.showResults) {
        this.setState({ showResults: false });
      }
      else {
        this.setState({ showResults: true });
      }
    }
    render() {
      const { t } = this.props;
        return (
            <div>
                <input type="submit" className="showgomb" value={this.state.showResults ? t('Eltüntet') : t('Megjelenít')} onClick={this.onClick} />
                { this.state.showResults ? <Variables /> : null }
            </div>
        );
    }
}

const ShowHide = withTranslation()(SH);
export default ShowHide;
