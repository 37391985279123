import React from 'react';
import './index.css';

import Template from './template/Template.js';
import { withTranslation } from 'react-i18next';

class ResPag extends React.Component {
  constructor(props) {
    super(props);
    this.getButtons = this.getButtons.bind(this)
  }
  getButtons(t) {
    var ret = [];
    if (parseInt(this.props.mmdata.elso_teszt_pontszam,10) >= 55 &&
        parseInt(this.props.mmdata.teszt_pontszam,10) >= 55 &&
        this.props.mmdata.regisztracio_java_se === '0000-00-00 00:00:00') {
          if (process.env.REACT_APP_PROJECT === 'project-hu') {
            ret.push(<span key="1"><a href={"https://auto.studicore.hu/integration/elegjo-teszt-d4f12c.php?email="+this.props.mmdata.email} className="gomb">{t('Regisztrálok az ajándékért')}</a><br/></span>)
          } else if (process.env.REACT_APP_PROJECT === 'project-intl') {
            ret.push(<span key="1"><a href={"https://studicore.com/free-sample?email="+this.props.mmdata.email} className="gomb">{t('Regisztrálok az ingyenes próbára')}</a><br/></span>)
          }
    }
    if (this.props.mmdata.regisztracio_java_se !== '0000-00-00 00:00:00') {
      ret.push(<a key="2" href="https://online.studicore.hu" className="gomb">{t('Tovább a StudiCore Online-ra')}</a>)
    }
    return ret;
  }
  render() {
    const { t } = this.props;
    return (
      <Template
        titlecomp=<h1>{t('Teszteredmény')}</h1>
        maincomp=<div className="questionbox">
          <div>
          <p>{t('A tesztet már kitöltötted korábban')}</p>
          <table className="resulttable">
          <tbody>
          <tr>
          <th>{t('Első kitöltés ideje')}</th>
          <td>{this.props.mmdata.elso_tesztkitoltes_ideje}</td>
          </tr>
          <tr>
          <th>{t('Első kitöltés pontszáma')}</th>
          <td>{this.props.mmdata.elso_teszt_pontszam}</td>
          </tr>
          <tr>
          <th>{t('Utolsó kitöltés ideje')}</th>
          <td>{this.props.mmdata.tesztkitoltes_ideje}</td>
          </tr>
          <tr>
          <th>{t('Utolsó kitöltés pontszáma')}</th>
          <td>{this.props.mmdata.teszt_pontszam}</td>
          </tr>
          </tbody>
          </table>
          <form onSubmit={this.props.onEvaluate}>
          <input type="submit" value={t('Kitöltöm újra')} className="gomb"/>
          </form>
          {this.getButtons(t)}
          </div>
        </div>
      />
    );
  }
}

const ResultPage = withTranslation()(ResPag);
export default ResultPage;
