import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import I18NQuestionTemplate from '../template/I18NQuestionTemplate';
import { withTranslation } from 'react-i18next';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 12,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: '#ccc',
  color: '#333',
  fontFamily: 'courier new',
  fontWeight: 'bold',
  fontSize: '14pt',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: '#eee',
  padding: grid,
  width: 260,
  margin: grid*2,
  paddingBottom: 2
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class DDQ extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        items: [
          { id: "item-11", content: "ELORE(130)" },
          { id: "item-2", content: "BALRA(112,62)" },
          { id: "item-10", content: "ELORE(120)" },
          { id: "item-8", content: "JOBBRA(157,38)" },
          { id: "item-9", content: "ELORE(50)" },
          { id: "item-6", content: "JOBBRA(90)" },
          { id: "item-0", content: "BALRA(22,62)" },
          { id: "item-7", content: "JOBBRA(112,62)" },
          { id: "item-1", content: "BALRA(67,38)" },
          { id: "item-3", content: "BALRA(157,38)" },
          { id: "item-4", content: "JOBBRA(22,62)" },
          { id: "item-5", content: "JOBBRA(67,38)" },
          { id: "item-12", content: "VÉGE" }
        ]
      };
      this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
      );

      this.setState({
        items
      });
      //console.log(items);
      var newArray = items.map((x) => x.content);
      var index = newArray.indexOf("VÉGE");
      newArray = newArray.slice(0, index);
      //console.log(newArray);
      var newState = {};
      for (var i = 0; i < newArray.length; i++) {
        newState[i] = newArray[i];
      }
      //console.log(newState);
      this.props.onChangeValue(newState);
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
      const { t } = this.props;
      var text = this.props.text;
      if (text === undefined) {
        text = t(this.props.textKey);
      }
      /*console.log(this.props.text);
      console.log(this.props.textKey);
      console.log(text);*/
      return (
        <I18NQuestionTemplate
          formContent=
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {t(item.content)}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {...this.props}
        />
      );
    }
/*  render() {
    if (isTouchDevice()) {
      return (
        <span>touch</span>
      );
    } else {
      return (
        <span>desktop</span>
      );
    }
  }*/
}

/*function isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
}*/

const DragAndDropQuiz2 = withTranslation()(DDQ);
export default DragAndDropQuiz2;
