import React from 'react';

import '../i18n.js';
import I18NComponent from './I18NComponent.js';
import Template from './Template.js';
import Progress from 'react-progressbar';

import { withTranslation } from 'react-i18next';

/**
textKey, textComponents VAGY text
formContentKey, formContentComponents VAGY formContent

*/
class QuestionTemplate extends React.Component {
  render() {
    const { t } = this.props;
    var percent = (this.props.num-0.95) / this.props.all * 100;
    /*if (React.isValidElement(this.props.text)) {
      var text = ReactDOMServer.renderToString(this.props.text);
    } else {
      var text = this.props.text;
    }*/
    /*if (React.isValidElement(this.props.text)) {
      var textBox = <div className="textBox">{this.props.text}</div>
    } else {
      var textBox = <div className="textBox" dangerouslySetInnerHTML={{__html : this.props.text}}/>
    }*/
    var textBox;
    if (this.props.textKey !== undefined) {
      let comp = this.props.components;
      if (comp === undefined) {
        comp = [];
      }
      textBox = <div className="textBox"><I18NComponent textKey={this.props.textKey} components={comp} /></div>
    } else {
      textBox = <div className="textBox">{this.props.text}</div>
    }
    var formContent;
    if (this.props.formContentKey !== undefined) {
      formContent = <I18NComponent textKey={this.props.formContentKey} components={this.props.formContentComponents} />
    } else {
      formContent = <div>{this.props.formContent}</div>
    }
    return (
      <Template
        titlecomp=<div><h1>{t('x-kérdés', { num: this.props.num })}</h1>
          <Progress completed={percent} color={"#1380AE"}/></div>
        maincomp=<div className="questionbox">
              <div>{textBox}
                <form onSubmit={this.props.onEvaluate}>
                    {formContent}
                    <p><input type="submit" value={t('Következő')} className="kovgomb"/></p>
                </form>
                <p className="remark">{t('Előző kérdésre visszatérni nem lehet!')}</p></div>
                </div>
        />
    );
  }
}

const I18NQuestionTemplate = withTranslation()(QuestionTemplate)

export default I18NQuestionTemplate;
