import React from 'react';
//import ReactDOM from 'react-dom';
//import './index.css';

import I18NQuestionTemplate from '../template/I18NQuestionTemplate';
import { withTranslation } from 'react-i18next';

class TAQ extends React.Component {
  constructor(props) {
    super(props);
    let initialValue = props.initial.replace(/\\n/g, '\n');
    this.state = { value: initialValue };
    this.props.onChangeValue(initialValue);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    this.setState({value: ev.target.value});
    this.props.onChangeValue(ev.target.value);
  }

  render() {
    return (
      <I18NQuestionTemplate
        formContent=<div className="textarea-wrapper">
          <textarea value={this.state.value} onChange={this.handleChange} rows="10"/>
        </div>
        {...this.props}
      />
    );
  }
}

const TextAreaQuiz = withTranslation()(TAQ);
export default TextAreaQuiz;
