import React from 'react';
//import ReactDOM from 'react-dom';
import '../index.css';

import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//import $ from 'jquery';
//import axios from 'axios';

function giftValue(mmdata) {
  var elso_pontszam = Number(mmdata.elso_teszt_pontszam);
  if (elso_pontszam === 0 || elso_pontszam >= 55) {
    return i18n.t('37000 Ft');
  } else {
    return i18n.t('19000 Ft');
  }
}

function SAPForm(props) {
  const { t } = useTranslation();
  if (process.env.REACT_APP_TWO_WEEKS_TRIAL === 'true') {
    var gValue = giftValue(props.mmdata);
    return (
      <center>
      <div dangerouslySetInnerHTML={{__html: t('gift_two_weeks', {giftValue: gValue})}}/>
      <a href={process.env.REACT_APP_URL_PASSWORD_GENERATOR + '?email=' + props.mmdata.email} className="jelszogomb">{t('Jelszó készítése')}</a>
      </center>
    );
  } else { // international
    if (props.mmdata.aktiv_java_se === '0') {
      return (
        <center><span dangerouslySetInnerHTML={{__html: t('no-two-weeks-reg-for-trial.' + process.env.REACT_APP_PROJECT)}}/><br/><br/>
        <a href={process.env.REACT_APP_URL_FREE_TRIAL + '?email=' + props.mmdata.email} className="gomb">{t('Regisztrálok az ingyenes próbára')}</a>
        </center>
      );
    } else {
      return (
        <center><span dangerouslySetInnerHTML={{__html: t('no-two-weeks-already-registered.' + process.env.REACT_APP_PROJECT)}}/><br/><br/>
        <a href={process.env.REACT_APP_URL_MOODLE_LOGIN} className="gomb">{t('Tovább a StudiCore Online-ra')}</a>
        </center>
      );
    }
  }
}

export default SAPForm;
