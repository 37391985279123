import React from 'react';

import ResultPage from './ResultPage';
import I18NInfoPage from './I18NInfoPage.js';

import CheckBoxQuiz from './question/CheckBoxQuizWithTemplate';
import InputQuiz from './question/InputQuizWithTemplate';
import TextInternalSelectQuiz from './question/TextInternalSelectQuizWithTemplate';
import DragAndDropQuiz2 from './question/DragAndDropQuizWithTemplate';
import TextAreaQuiz from './question/TextAreaQuizWithTemplate';
import Evaluation from './evaluation/Evaluation';
import ShowHide from './question/ShowHide.js'

import axios from 'axios';
import axiosRetry from 'axios-retry';
import queryString from 'query-string';

import i18n from 'i18next';
import './i18n';
import { withTranslation, Trans } from 'react-i18next';

axiosRetry(axios, { retries: 10 });
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay});

class ProgQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.createState(-100);

    const parsed1 = queryString.parse(window.location.search);
    const email = parsed1.email;
    const v = parsed1.v;
    if (email == null) {
      this.state = {num: -1};
    } else {
      var self = this;
      axios.post(process.env.REACT_APP_URL_SAPI_DATA_GETTER, {email: email})
      //axios.post('http://localhost:8100/integration/getter_mm_test_data.php', data)
      .then(function(response) {
        //console.log(response);
        if (response.data === '') {
          self.setState({num: -2});
        }
        else if (response.data.tesztkitoltes_ideje !== '0000-00-00 00:00:00' && (v === undefined || v === "1")) {
          self.setState({num: 0, mmdata: response.data}); // 0 volt
        } else {
          self.setState({num: 1, mmdata: response.data}); // 1 volt
        }
      })
      .catch(function(error) {
        console.log(error);
        // TODO: loglevel, loglevel-plugin-remote és bekonfigurálni
        // More info: https://www.loggly.com/blog/best-practices-for-client-side-logging-and-error-handling-in-react/
      })
    }
//    this.handleChange = this.handleChange.bind(this);
    this.handleChangeToValue = this.handleChangeToValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.createState = this.createState.bind(this);
    this.restartTest = this.restartTest.bind(this);
  }

/*  componentDidMount(){
    document.title = i18n.t('document.title');
  }*/

  handleSubmit(q, event) {
    this.setState({num: this.state.num+1});
    event.preventDefault();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  handleChangeToValue(q, newValue) {
      let ans = this.state.answers;
      ans[q] = {'value': newValue};
      this.setState({answers: ans});
      //console.log(answers);
  }
  /*handleChange(q, event) {
    this.handleChangeToValue(q, event.target.value);
  }*/
  handleNext(q, event) {
    this.setState({num: this.state.num+1});
  }
  createState(startNum) {
    return {value: '', num: startNum, answers:
      {
        1: {value: ""},
        2: {value: {'a': false, 'b': false, 'c': false, 'd': false, 'e': false, 'f': false}},
        3: {value: ""},
        4: {value: {0:{value:"", label:"semmi"}, 1:{value:"", label:"semmi"}, 2:{value:"", label:"semmi"}, 3:{value:"", label:"semmi"},
                    4:{value:"", label:"semmi"}, 5:{value:"", label:"semmi"}, 6:{value:"", label:"semmi"}, 7:{value:"", label:"semmi"},
                    8:{value:"", label:"semmi"}, 9:{value:"", label:"semmi"}}},
        5: {value: {0:'semmi', 1:'semmi', 2:'semmi', 3:'semmi', 4:'semmi', 5:'semmi', 6:'semmi', 7:'semmi'}},
        6: {value: {'a': false, 'b': false, 'c': false, 'd': false, 'e': false, 'f': false, 'g': false}},
        7: {value: i18n.t('semmi')}
        // beírni a többi default értéket is?
      }};
  }
  restartTest() {
    //console.log("restartTest");
    this.setState(this.createState(1));
  }
  render() {
    document.title = i18n.t('document.title');
    //console.log(this.state.mmdata);
    var all = "7";
    if (this.state.num === -100) {
      return (<I18NInfoPage
                textKey="A teszt töltődik"
                titleKey="Kis türelmet"/>
              );
    } else if (this.state.num === -2) {
      return (<I18NInfoPage
                textKey="Hibás email cím"
                titleKey="Hiba"
                params={{support_email: process.env.REACT_APP_SUPPORT_EMAIL}}/>
              );
    } else if (this.state.num === -1) {
      return (<I18NInfoPage
                textKey="Hiányzó email cím"
                titleKey="Hiba"
                params={{support_email: process.env.REACT_APP_SUPPORT_EMAIL}}/>
              );
    } else if (this.state.num === 0) {
      return (<ResultPage
                mmdata={this.state.mmdata}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}/>
              );
    } else if (this.state.num === 1) {
      return (<InputQuiz
                text=
                <div><center><Trans>{'test-instructions.'+process.env.REACT_APP_PROJECT}</Trans></center><br/><br/>
                <Trans>test-feladat1-szoveg</Trans><br/><br/></div>
                num={this.state.num}
                all={all}
                value={this.state.answers[1].value}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChange={(e) => this.handleChangeToValue(this.state.num,e.target.value)}/>);
    } else if (this.state.num === 2) {
      return (<CheckBoxQuiz
                text=<div><Trans>test-feladat2-szoveg1</Trans><br/>
                  <blockquote><Trans>test-feladat1-szoveg</Trans></blockquote>
                  <Trans i18nKey="test-feladat2-szoveg2">Ha a konkrét mennyiségek helyett <code>a</code>, <code>b</code>, <code>c</code>, <code>d</code>, <code>e</code>, <code>f</code> paraméterek szerepelnének,<br/> mely válaszok lennének igazak a kalkulációra?</Trans></div>
                num={this.state.num}
                all={all}
                options={[
                  {value: 'a', label: <Trans>a számítás alapja az, ami a receptben legnagyobb tömegben (darabban) szükséges</Trans> },
                  {value: 'b', label: <Trans>osztani tuti kell benne</Trans> },
                  {value: 'c', label: <Trans>bizonyos részeredmények közül a legkisebbet kell kiválasztani</Trans>},
                  {value: 'd', label: <Trans>az biztos, hogy ki kell számítani a recept által előírt és a meglévő mennyiség viszonyát</Trans>},
                  {value: 'e', label: <Trans>bizonyos részeredmények közül a legnagyobbat kell kiválasztani</Trans>},
                  {value: 'f', label: <Trans>megnézzük, melyik anyag áll a legnagyobb tömegben (darabban) rendelkezésre</Trans>}
                ]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(e) => this.handleChangeToValue(this.state.num,e)}/>);
    } else if (this.state.num === 3) {
      return (<InputQuiz
                text=<div><Trans i18nKey="test-feladat3-szoveg">
                  Sütit készülünk sütni.<br/>
                  Van otthon 1 kg cukrunk, 12 tojásunk, 46 dkg lisztünk.<br/>
                  A süti egy adagjának elkészítéséhez 10 dkg cukor, 2 tojás és 20 dkg liszt kell.<br/>
                  Sajnos azt vettük észre, hogy az otthon lévő összetevők mindegyike holnapra megromlik, így fel akarjuk használni az egészet.<br/>
                  Mennyi cukrot, tojást és lisztet kell vásárolnunk ahhoz, hogy az otthon lévő összetevők mind elfogyjanak?<br/>
                  A válasz formátuma:
                  <code>36 dkg cukor, 5 tojás, 12 dkg liszt.</code><br/>
                  Figyelj a formátumra! Minden szóköz, írásjel számít!</Trans></div>
                num={this.state.num}
                all={all}
                value={this.state.answers[3].value}
                inputSize="40"
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChange={(e) => this.handleChangeToValue(this.state.num,e.target.value)}/>);
    } else if (this.state.num === 4) {
              var operands = [{value: 'a', label: 'a'}, {value: 'b', label: 'b'}, {value: 'c', label: 'c'}, {value: 'd', label: 'd'}, {value: 'e', label: 'e'}, {value: 'f', label: 'f'}, {value: '1', label: '1'}, {value: '2', label: '2'}, {value: '3', label: '3'}];
              var operators = [{value: 'plus', label: '+'}, {value: 'minus', label: '-'}, {value: 'mul', label: '*'}, {value: 'div', label: '/'}];
              var aggregates = [{value: 'min', label: i18n.t('minimumát')}, {value: 'sum', label: i18n.t('összegét')}, {value: 'max', label: i18n.t('maximumát')}, {value: 'mul', label: i18n.t('szorzatát')}, {value: 'log', label: i18n.t('logaritmusát')}];
              return (<TextInternalSelectQuiz
                        formContentKey="test-feladat4-szoveg"
                        num={this.state.num}
                        all={all}
                        options={[
                          operands, operators, operands,
                          operands, operators, operands,
                          operands, operators, operands,
                          aggregates
                        ]}
                        onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                        onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
                      />);
    } else if (this.state.num === 5) {
      return (<DragAndDropQuiz2
                textKey="test-feladat5-szoveg"
                num={this.state.num}
                all={all}
                places="8"
                possibilities={["not implemented"]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
                />);
    } else if (this.state.num === 6) {
      return (<CheckBoxQuiz
                text=<div><p><Trans i18nKey="test-feladat6-szoveg1">Kérlek olvasd el az alábbi szöveget, és értsd meg a benne foglaltakat:</Trans><br/></p>
                  <ShowHide/><p><Trans i18nKey="test-feladat6-szoveg2">Jelöld be az összes <b>igaz</b> választ!</Trans><br/></p></div>
                num={this.state.num}
                all={all}
                options={[
                  {value: 'a', label: <Trans>a változó egyidőben több adatot is tárolhat</Trans>},
                  {value: 'b', label: <Trans>a számítógépek adatokkal dolgoznak</Trans>},
                  {value: 'c', label: <Trans>az "int a;" utasítás hatására létrejön egy "a" nevű változó</Trans>},
                  {value: 'd', label: <Trans>az "=" hatására a jobb oldalon álló értékkel felülírjuk a bal oldalon álló változó tartalmát</Trans>},
                  {value: 'e', label: <Trans>a változó értéke a program végéig az marad, amit először kapott</Trans>},
                  {value: 'f', label: <Trans>a számítógép ismeri az eltárolt adat értelmét</Trans>},
                  {value: 'g', label: <Trans>a változónak van neve</Trans>}
                ]}
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(e) => this.handleChangeToValue(this.state.num,e)}/>);
    } else if (this.state.num === 7) {
      return (<TextAreaQuiz
                textKey="test-feladat7-szoveg"
                //<div><div><Trans i18nKey='test-feladat7-szoveg1'>{{code: "<code>int a = 7;<br/>int b = 3;</code>"}}</Trans></div><ShowHide/><p><Trans i18nKey='test-feladat7-szoveg2'/></p></div>
                components={[<ShowHide/>]}
                num={this.state.num}
                all={all}
                initial="int a = 7;\nint b = 3;\n"
                onEvaluate={(e) => this.handleSubmit(this.state.num,e)}
                onChangeValue={(val) => this.handleChangeToValue(this.state.num,val)}
                />);
    } else {
      return (<Evaluation answers={this.state.answers}
                mmdata={this.state.mmdata}
                onEvaluate={(e) => this.restartTest()}
                />);
    }
  }
}

/*class Proba extends React.Component {
  constructor(props) {
    super(props)
    this.state = {adContent: 'Töltődik...'};
    var self = this;
    axios.get('https://www.studicore.hu/hirdetes/teszt-vege/')
    .then(function(response) {
      //console.log(response);
      var el = document.createElement("html");
      el.innerHTML = response.data;
      document.body.appendChild(el);
      var adContent = document.getElementById("hirdetes").innerHTML;
      document.body.removeChild(el);
      self.setState({adContent: adContent});
    })
    .catch(function(error) {
      console.log(error);
    })
  }
  render() {
    const adContent = this.state.adContent;
    //const adDiv = <div dangerouslySetInnerHTML={{__html: adContent}}></div>
    //return (adDiv);
    const adPage =
      <div>
      Az oldal hamarosan automatikusan átirányít
      <InfoPage
        title="Bosszankodásmentes számítógépválasztó -50%"
        text={adContent} />
      </div>
    return (adPage)
  }
}*/
//ReactDOM.render(<Proba />, document.getElementById('root'));
export default withTranslation()(ProgQuiz);
