import React from 'react';

import I18NQuestionTemplate from '../template/I18NQuestionTemplate';
import { Trans } from 'react-i18next';

class InputQuiz extends React.Component {
  render() {
    return (
      <I18NQuestionTemplate
        formContent=<div><label htmlFor="valasz">
            <Trans>Válasz</Trans>
          </label>
          <div className="valasz-wrapper">
            <input type="text" id="valasz" className="valasz" value={this.props.value} onChange={this.props.onChange} size={this.props.inputSize}/>
          </div>
        </div>
        {...this.props}
      />
      );
  }
}

export default InputQuiz;
