/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";

import Template from '../template/Template.js';
import { BigEvaluationTable, SmallEvaluationTable } from './EvaluationTable.js';
import Points, { calculate_points } from './Points.js';
import Progress from 'react-progressbar';
import MediaQuery from 'react-responsive';

import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

function addLang(url) {
  return url + '?lang=' + i18n.language;
}

const eval_start = async (key, param) => {
//  console.log('EvalStartData: ' + String(param));
//  let formData = new FormData();
//  formData.append('answers', JSON.stringify(param));
  var data = await axios.post(
    process.env.REACT_APP_URL_EVAL_START, param
  );
  return data;
};

const getTeszt1 = async (key, answer) => {
  var data = await axios.post(
    addLang(process.env.REACT_APP_URL_EVAL_TEST1), { answer: answer }
  );
  return data;
};

const getTeszt2 = async (key, answer) => {
  var data = await axios.post(
    addLang(process.env.REACT_APP_URL_EVAL_TEST2), answer
  );
  return data;
};

const getTeszt3 = async (key, answer) => {
  var data = await axios.post(
    addLang(process.env.REACT_APP_URL_EVAL_TEST3), { answer: answer}
  );
  return data;
};

const getTeszt4 = async (key, answer) => {
/*  var answer = {"0":{"value":"", "label":"semmi"}, "1":{"value":"", "label":"semmi"}, "2":{"value":"", "label":"semmi"}, "3":{"value":"", "label":"semmi"},
                      "4":{"value":"", "label":"semmi"}, "5":{"value":"", "label":"semmi"}, "6":{"value":"", "label":"semmi"}, "7":{"value":"", "label":"semmi"},
                      "8":{"value":"", "label":"semmi"}, "9":{"value":"", "label":"semmi"}};*/
  var data = await axios.post(
    //"http://localhost:8080/teszt4", answer
    addLang(process.env.REACT_APP_URL_EVAL_TEST4), answer
  );
  return data;
};

const getTeszt5 = async (key, answer) => {
  //var answer = {0:'semmi', 1:'semmi', 2:'semmi', 3:'semmi', 4:'semmi', 5:'semmi', 6:'semmi', 7:'semmi'};
  var data = await axios.post(
    addLang(process.env.REACT_APP_URL_EVAL_TEST5), answer
    //"https://teszt.studicore.hu/webjext/teszt4", answer
  );
  return data;
};

const getTeszt6 = async (key, answer) => {
  //var answer = {'a': false, 'b': false, 'c': false, 'd': false, 'e': false, 'f': false, 'g': false};
  var data = await axios.post(
    addLang(process.env.REACT_APP_URL_EVAL_TEST6), answer
    //"https://teszt.studicore.hu/webjext/teszt4", answer
  );
  return data;
};

const getTeszt7 = async (key, answer) => {
  //var answer = "semmi";
  var data = await axios.post(
    addLang(process.env.REACT_APP_URL_EVAL_TEST7), { answer: answer }
    //"https://teszt.studicore.hu/webjext/teszt4", answer
  );
  return data;
};

const eval_end = async (key, param) => {
  console.log(param);
  var data = await axios.post(
    process.env.REACT_APP_URL_EVAL_END, param
  );
  return data;
}


function nonnull(answer) {
  return answer ? answer.value : '';
}

function checkboxAnswer(answer) {
//  const { t, i18n } = useTranslation();
  answer = nonnull(answer);
  var chars = "abcdefghijklmnopqrstuvwxyz";
  var ret = '';
  var first = true;
  for (var i = 0; i <= chars.length; i++) {
    if (answer[chars.charAt(i)]) {
      if (!first) {
        ret += ', ';
      }
      first = false;
      ret += chars.charAt(i);
    }
  }
  if (ret === '') {
    ret = i18n.t('nincs');
  }
  return i18n.t('Bejelölt válaszok')+' '+ret;
}

function dragAndDropAnswer(answer) {
  answer = nonnull(answer);
  var ret = '';
  var first = true;
  for (var i = 0; i <= 30; i++) {
    if (answer[i] != null) {
      if (!first) {
        ret += ', ';
      }
      first = false;
      ret += i18n.t(answer[i]);
    }
  }
  return ret;
}

function dropdownAnswer(answer) {
  answer = nonnull(answer);
  var ret = '';
  var first = true;
  for (var i = 0; i <= 30; i++) {
    if (answer[i] != null) {
      if (!first) {
        ret += ', ';
      }
      first = false;
      ret += i18n.t(answer[i].label);
    }
  }
  return ret;
}

function eval_end_data(props, res, line, email) {
  //console.log(line);
  var ret = {'email': email,
          'points': calculate_points(line),
          'answers': props.answers};
  console.log(ret);
  return ret;
}

function Evaluation(props) {
  const { t } = useTranslation();

  var line = [
            {points: '?', review: t('Letöltés'), maxpoints:  5, readable: nonnull(props.answers[1])},
            {points: '?', review: t('Letöltés'), maxpoints: 10, readable: checkboxAnswer(props.answers[2])},
            {points: '?', review: t('Letöltés'), maxpoints: 15, readable: nonnull(props.answers[3])},
            {points: '?', review: t('Letöltés'), maxpoints: 20, readable: dropdownAnswer(props.answers[4])},
            {points: '?', review: t('Letöltés'), maxpoints: 15, readable: dragAndDropAnswer(props.answers[5])},
            {points: '?', review: t('Letöltés'), maxpoints: 10, readable: checkboxAnswer(props.answers[6])},
            {points: '?', review: t('Letöltés'), maxpoints: 25, readable: nonnull(props.answers[7])}
          ];

  // eval_start: email és answers JSON formátumban!
  let email = props.mmdata.email;
  let eval_start_data = {'email': email, 'answers': props.answers};
  const eval_start_res = useQuery(["eval_start", eval_start_data], eval_start);
  const res = [
    useQuery(eval_start_res.data && ["teszt1", nonnull(props.answers[1])], getTeszt1),
    useQuery(eval_start_res.data && ["teszt2", nonnull(props.answers[2])], getTeszt2),
    useQuery(eval_start_res.data && ["teszt3", nonnull(props.answers[3])], getTeszt3),
    useQuery(eval_start_res.data && ["teszt4", nonnull(props.answers[4])], getTeszt4),
    useQuery(eval_start_res.data && ["teszt5", nonnull(props.answers[5])], getTeszt5),
    useQuery(eval_start_res.data && ["teszt6", nonnull(props.answers[6])], getTeszt6),
    useQuery(eval_start_res.data && ["teszt7", nonnull(props.answers[7])], getTeszt7)
  ];
  /*var line = new Array(7);
  line.fill({points: '?', review: 'Letöltés...', color: '#FFFFFF', readable: 'readableanswer'});*/
//  const { status, data, error, isFetching } = useQuery("teszt2", getTeszt2);
  for (let i = 0; i < res.length; i++) {
    if (res[i] !== undefined) {
      if (res[i].data !== undefined) {
        res[i].data.data.color = '#aaaaaa';
        line[i] = {...line[i], ...res[i].data.data};
      } else if (res[i].error != null) {
        line[i].error = res[i].error;
        line[i].points = t('HIBA');
        line[i].review = t('Belső hiba!');
        line[i].solution = '';
        line.error = 'ERROR';
      }
    }
  }

  // eval_end: email, points és answers JSON formátumban!
  useQuery(res[0].data && res[1].data && res[2].data && res[3].data && res[4].data && res[5].data && res[6].data && ["eval_end", eval_end_data(props, res, line, email)], eval_end);

  return (
  //  <div>{eval_start_res.data !== undefined ? eval_start_res.data.data['elso_teszt_pontszam'] : 'Loading...'}</div>
    <Template
      titlecomp=<div><h1>{t('Értékelés')}</h1>
            <Progress completed={100} color={"#1380AE"}/></div>
      maincomp=<div>
          <div className="evaluation-wrapper">
            <MediaQuery minWidth={601}>
              <BigEvaluationTable line={line}/>
            </MediaQuery>
            <MediaQuery maxWidth={600}>
              <SmallEvaluationTable line={line}/>
            </MediaQuery>
          </div>
          <Points line={line} email={props.email} mmdata={props.mmdata} onEvaluate={props.onEvaluate}/>
          </div>
      />
  )
}

export default Evaluation;
