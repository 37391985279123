import React from 'react';

import { useTranslation } from 'react-i18next';

function tableBackgroundColor(points) {
  if (points === '?') {
    return "#fff";
  } else if (isNaN(points)) {
    return "#f00";
  } else if (points === 100) {
    return "#d4edda"; //"#21ff28"; //"#25cc16"; // zöld: "#009900";
  } else if (points === 0) {
    return "#f8d7da"; //"#ff3b21";//"#e84040"; // piros: "#990000";
  } else {
    return "#fff3cd"; //"#f2e93c"; // sárga: "#cc8502";
  }
}


function SmallEvaluationTable(props) {
  const { t } = useTranslation();
  var smallEvaluationTable = [];
    for (let i = 0; i < props.line.length; i++) {
      smallEvaluationTable.push(
        <div key={i} className="evaluation">
          <div className="evaluation-one-block" style={{backgroundColor: tableBackgroundColor(props.line[i].points)}}>
            <div className="evaluation-one-row">
              <div className="evaluation-id evaluation-header">{t('Feladat sorszáma')}</div>
              <div className="evaluation-id">{i+1}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-answer evaluation-header">{t('Válaszod')}</div>
              <div className="evaluation-answer">{t(props.line[i].readable)}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-solution evaluation-header">{t('Helyes')}</div>
              <div className="evaluation-solution">{t(props.line[i].solution)}</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-points evaluation-header">{t('%')}</div>
              <div className="evaluation-points">{props.line[i].points * props.line[i].maxpoints / 100} / {props.line[i].maxpoints} ({props.line[i].points} %)</div>
            </div>
            <div className="evaluation-one-row">
              <div className="evaluation-review evaluation-header">{t('Magyarázat')}</div>
              <div className="evaluation-review">{t(props.line[i].review)}</div>
            </div>
          </div>
        </div>
        );
    }
  return (<div>{smallEvaluationTable}</div>)
}

function BigEvaluationTable(props) {
  const { t } = useTranslation();
  var evalTable = [];
      for (var i = 0; i < props.line.length; i++) {
        evalTable.push(
          <tr key={i} bgcolor={tableBackgroundColor(props.line[i].points)}>
            <td className="evaluation-id">{i+1}</td>
            <td className="evaluation-answer">{props.line[i].readable}</td>
            <td className="evaluation-solution">{t(props.line[i].solution)}</td>
            <td className="evaluation-points">{props.line[i].points * props.line[i].maxpoints / 100} / {props.line[i].maxpoints}<br/>({props.line[i].points}%)</td>
            <td className="evaluation-review">{t(props.line[i].review)}</td>
          </tr>);
      }
  return (<table className="evaluation">
        <colgroup>
          <col width="5%"/>
          <col width="30%"/>
          <col width="30%"/>
          <col width="5%"/>
          <col width="30%"/>
        </colgroup>
        <tbody>
          <tr>
            <th className="evaluation-id">{t('#')}</th>
            <th className="evaluation-answer">{t('Válaszod')}</th>
            <th className="evaluation-solution">{t('Helyes')}</th>
            <th className="evaluation-points">{t('%')}</th>
            <th className="evaluation-review">{t('Magyarázat')}</th>
          </tr>
          {evalTable}
        </tbody>
      </table>);
}

export { BigEvaluationTable, SmallEvaluationTable }
